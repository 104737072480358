<template>
  <div class="content">
    <div class="content__title content__title--party content__title--start">
      <router-link
        :to="{ name: 'calendar' }"
        class="button button--light button--no-indent button--small"
      >
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z" fill="#2B93E7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z" fill="#2B93E7"/>
        </svg>
      </router-link>
      <h1 class="title title--big title--theme">Редактирование отчёта</h1>
    </div>
    <form class="form" @submit.prevent="onCheckForm">
      <div class="form__content">
        <div class="form__part">
          <div class="form-group">
            <label for="year">Год</label>
            <div class="form-control">
              <input
                id="year"
                type="text"
                class="form-control__input"
                name="year"
                v-model.trim="form.year"
                readonly
              />
            </div>
          </div>
          <div class="form-group">
            <label>Тип</label>
            <div class="form-group__party">
              <label class="radio">
                <div class="radio__text">Дата</div>
                <input @change="onCheckType" type="radio" name="type_of" v-model.number="form.type_of" value="1" />
                <div class="radio__radiomark"></div>
              </label>
              <label class="radio">
                <div class="radio__text">Текст</div>
                <input @change="onCheckType" type="radio" name="type_of" v-model.number="form.type_of" value="2" />
                <div class="radio__radiomark"></div>
              </label>
            </div>
          </div>
          <div v-if="form.type_of === 1" class="form-group">
            <label for="date">Срок подачи отчёта<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.date.$error }">
              <masked-input
                v-model="form.date"
                name="date"
                mask="11.11.1111"
                placeholder="дд.мм.гггг"
                class="form-control__input"
                id="date"
                autocomplete="off"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.date.$dirty && !$v.form.date.required">
              Обязательное поле
            </div>
          </div>
          <div v-else class="form-group">
            <label for="date_note">Срок подачи отчёта<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.date_note.$error }">
              <textarea
                id="date_note"
                name="date_note"
                class="form-control__textarea"
                v-model.trim="form.date_note"
                placeholder="Введите срок подачи отчёта"
              >
              </textarea>
            </div>
            <div class="form-group__error" v-if="$v.form.date_note.$dirty && !$v.form.date_note.required">
              Обязательное поле
            </div>
          </div>
          <div class="form-group">
            <label>Орган подачи отчёта<sup>*</sup></label>
            <v-select
              v-model="form.item"
              :reduce="item => item.id"
              :searchable="false"
              :clearable="false"
              :options="item_options"
              :get-option-label="getLabel"
              placeholder="Выберите орган"
              class="select"
              :class="{'select--error': $v.form.item.$error}"
            >
              <template slot="open-indicator">
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                </svg>
              </template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">
                  {{ option.name }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.name }}
                </div>
              </template>
            </v-select>
            <div class="form-group__error" v-if="$v.form.item.$dirty && !$v.form.item.required">
              Обязательное поле
            </div>
          </div>
          <div class="form-group">
            <label for="item_name">Название отчёта<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.name.$error }">
            <textarea
              id="item_name"
              name="item_name"
              class="form-control__textarea"
              v-model.trim="form.name"
              placeholder="Введите название отчёта"
            >
            </textarea>
            </div>
            <div class="form-group__error" v-if="$v.form.name.$dirty && !$v.form.name.required">
              Обязательное поле
            </div>
          </div>
        </div>
        <div class="form__part">
          <div class="form-group">
            <label for="period">Отчётный период<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.period.$error }">
            <textarea
              id="period"
              name="period"
              class="form-control__textarea"
              v-model.trim="form.period"
              placeholder="Введите отчётный период"
            >
            </textarea>
            </div>
            <div class="form-group__error" v-if="$v.form.period.$dirty && !$v.form.period.required">
              Обязательное поле
            </div>
          </div>
          <div class="form-group">
            <label for="note">Примечание</label>
            <div class="form-control">
            <textarea
              id="note"
              name="note"
              class="form-control__textarea"
              v-model.trim="form.note"
              placeholder="Введите примечание"
            >
            </textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="holder holder--end">
        <button class="button" type="submit" :class="{ 'preload': loading }" :disabled="$v.form.$error">
          <span>Сохранить изменения</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'
import MaskedInput from 'vue-masked-input'

export default {
  name: 'CalendarEdit',
  mixins: [ validationMixin ],
  components: {
    MaskedInput,
  },
  validations: {
    form: {
      item: { required },
      name: { required },
      period: { required },
      date: {
        required: requiredIf(function(){
          return this.form.type_of === 1
        })
      },
      date_note: {
        required: requiredIf(function(){
          return this.form.type_of === 2
        })
      },
    },
  },
  data() {
    return {
      form: {
        year: '',
      },
      manage: {},
      loading: false,
      item_options: [],
    }
  },
  computed: {
    userRole() {
      return this.$store.state.userRole
    }
  },
  mounted() {
    this.form.year = new Date().getFullYear()
    this.$store.dispatch('government/GET_LIST')
      .then(response => {this.item_options = response.data.results})
    this.fetchForm()
  },
  methods: {
    fetchForm () {
      this.$store.dispatch('calendar/GET_DATA', this.$route.params.id)
        .then(response => {
          this.form = {...this.form, ...response.data}
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка получения данных'
          })
        })
    },
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        });
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.loading = true
      if (!this.form.date) {
        this.form.date = null
      }
      this.$store.dispatch('calendar/PATCH_DATA', { id: this.$route.params.id, data: this.form })
        .then(() => {
          this.loading = false
          this.$router.push({ name: 'calendar' })
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Изменения сохранены.'
          });
        })
        .catch(() => {
          this.loading = false
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка сохранения изменений.'
          });
        });
    },
    getLabel (option) {
      if (typeof option === 'object') {
        if (Object.prototype.hasOwnProperty.call(!option, this.label)) {
          return console.warn(
            `[vue-select warn]: Label key "option.${this.label}" does not` +
            ` exist in options object ${JSON.stringify(option)}.\n` +
            'https://vue-select.org/api/props.html#getoptionlabel'
          );
        }
        return option[this.label];
      }
      return option;
    },
    onCheckType() {
      if (this.form.type_of === 1) {
        this.form.date_note = ''
      } else {
        this.form.date = null
      }
    }
  }
}
</script>
